.imb-input {
  input:focus-within ~ label,
  textarea:focus-within ~ label,
  input:not(:placeholder-shown) ~ label,
  textarea:not(:placeholder-shown) ~ label {
    @apply text-xs transform -translate-y-2;
  }

  input:focus-within ~ label,
  textarea:focus-within ~ label {
    @apply text-orange-100;
  }
}

ion-datetime {
  --font-size: 14px;
  font-size: 14px;
}
