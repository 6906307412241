@tailwind base;
@tailwind components;
@tailwind utilities;

@import './components/input.css';
@import './components/checkbox.css';
@import './components/button.css';

@import './wheel-component.css';

/* :root {
  direction: rtl;
} */

/* ion-app {
    margin-top: env(safe-area-inset-top);
    margin-bottom: env(safe-area-inset-bottom)
} */
