ion-item.custom-checkbox::part(native) {
  @apply pl-0;
}
.custom-checkbox {
  ion-checkbox::part(container) {
    @apply w-5 h-5 border-orange-100;
  }

  ion-checkbox.fail::part(container) {
    @apply border-red-100;
  }

  ion-checkbox.checkbox-checked::part(container) {
    @apply bg-orange-100;
  }

  &.item .sc-ion-label-ios-h {
    @apply text-sm;
  }
}
